<template>
  <div>

  </div>
</template>

<script>
import { mapState } from 'vuex';
import { createHelpers } from 'vuex-map-fields';
import { mixin } from '@/mixins/mixin.js';

const { mapFields } = createHelpers({
  getterType: 'tasksStore/getEntryField',
  mutationType: 'tasksStore/updateEntryField',
});

export default {
  name: 'Task',
  mixins: [mixin],
  computed: {
    ...mapState({
      tasksStore: (state) => state.tasksStore,
    }),
    ...mapFields([
      'show',
      'valid',
      'section',
      'mode',
      'id',
      'fk_providerID',
      'fk_userID_Assigned',
      'fk_userID_Author',
      'd_date',
      'f_alert',
      'd_subject',
      'd_task',
      'f_type',
      'f_status',
      'date1',
    ]),
  },
  props: {
    /* All List = 0, Provider = 1, Client = 2, Dashboard = 3 */
    taskSection: {
      type: Number,
      required: false,
    },
  },

  watch: {
    date1() {
      this.d_date = this.$_formatDate(this.date1);
    },
  },
  data() {
    return {
      menu: false,
    };
  },
  methods: {

    async newEntry() {
      /* Store authorized user in store */
      const taskTypes = this.$store.getters['taskTypesStore/getTaskTypes'];
      if (taskTypes.length === 0) {
        await this.$store.dispatch('taskTypesStore/fetch');
      }
      let users;
      users = this.$store.getters['usersStore/getUsers'];
      if (users.length === 0) {
        await this.$store.dispatch('usersStore/fetch');
      }

      /* Provider Section */
      if (this.taskSection === 1) {
        const provider = this.$store.getters['providersStore/getProvider'];
        this.fk_providerID = provider.id;
      }
      this.valid = true;
      this.section = this.taskSection;
      this.mode = 1;
      this.id = '';
      this.fk_userID_Assigned = '';
      this.fk_userID_Author = '';
      this.d_date = '';
      this.f_alert = '';
      this.d_subject = '';
      this.d_task = '';
      this.f_type = '';
      this.f_status = true;
      this.date1 = '';
      this.show = true;
    },

    cancelEntry() {
      this.show = false;
    },

    async saveEntry() {
      if (this.$refs.entryForm.validate()) {
        const entry = this.$store.getters['tasksStore/getEntry'];
        /* Create Task */
        if (this.mode === 1) {
          const user = this.$store.getters['usersStore/getAuthUser'];
          this.fk_userID_Author = user.id;
          await this.$store.dispatch('tasksStore/create', entry);
        }
        /* Edit Task */
        else {
          await this.$store.dispatch('tasksStore/update', entry);
        }
        this.cancelEntry();
      }
    },
  },
};
</script>

<style scoped>
  .clickable{
    cursor:pointer;
  }
  button {
    outline: none;
  }
</style>
